
.debug {
  border: 2px solid red;
}
.icon-bar { 
  color:white;
}
img{
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
#fader {
  background-color:#000;
  width:100%;
  height:100%;
  position:absolute;
  top: 0;
  left: 0;
  height: 96vh;
  z-index: 0;
}
// input.login {
//   width: 30%;
//   min-width: 300px;
//   background-color: transparent !important;
//   height: 45px;
//   border: 1px solid white;
//   outline: none;
//   letter-spacing: 2px;
//   transition: letter-spacing 1s ease !important;
// }
// input.login:focus {
//   outline: orange solid 1px;
// }
// input.login:hover {
//   letter-spacing: 3px;
// }
// #mainpanel {
//   width: 40%;
//   padding: 20px 0px 80px 0px;
//   min-height: 250px;
//   min-width: 320px;
//   background-color: rgba(0, 0, 0, 0.6);
//   height: 100%;
// }
// ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
//   color: rgba(255,255,255, 0.5);
// }
// ::-moz-placeholder { /* Firefox 19+ */
//   color: rgba(255,255,255, 0.5);
// }
// :-ms-input-placeholder { /* IE 10+ */
//   color: #cccccc;
// }
// :-moz-placeholder { /* Firefox 18- */
//   color: rgba(255,255,255, 0.5);
// }
// .login-label {
//   text-align: left !important;
//   margin-left: 25px;
//   font-family: "Calibre Regular";
// }
.forgotlink {
    color: #949494 !important;
    text-align: center;
    display: block;
    margin: 1rem 0 0;
	font-size: .8rem;
	opacity: .8;
  cursor: pointer;
}
// #btnNext, #btnNextForgot {
//   border-radius:none !important;
//   background-color:transparent !important;
//   border:none !important;
//   width: 260px;
//   outline:none !important;
//   min-width: 150px !important;
//   letter-spacing: 5px;
// }
#forgetIt {
  background-color: rgba(0, 0, 0, 0.705);
  width:100%;
}

#magic_bg {
    position: fixed;
    top:0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
	background-position: center;
    background: url('') no-repeat;
    background-size: cover;
    transition: 1s opacity;
}
#idErrorMsg {
    color:red;
    text-align: center;
}
@keyframes screenFadeIn {
  from {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeInScreen {
  -webkit-animation-name: screenFadeIn;
  animation-name: screenFadeIn;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}
@font-face {
    font-family: "Calibre Regular"; src: url(fonts/Calibre-Regular.otf);
}

.cog-rotating {
    -webkit-animation:spin 3s linear infinite;
    -moz-animation:spin 3s linear infinite;
    animation:spin 3s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }