html,
body {
	height: 100%;
}


:root {
    font-size: 16px;
}

body {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #000000;
	color: #eee;
	font-family: 'Source Sans Pro';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2 {
	font-family: 'Playfair Display', serif;
	font-weight: bold;
}

h1 {
	letter-spacing: 2px;
}

.updatepass, .passupdate {
	width: 100%;
	padding: 2.5rem;
	margin: auto;
	background: rgba(0, 0, 0, 0.8);
	box-shadow: 0px 0px 17px 3px rgba(26,26,26,.8);
	label {
		display: block;
		text-transform: uppercase;
		font-size: .9rem;
		letter-spacing: 1px;
		text-align: center;
		color: #888;
	}
	.form-control {
		font-size: 1rem;
		max-width: 18rem;
		margin: 0 auto;
	}
}

.passupdate {
	.form-control-lg {
		font-size: 1.5rem;
		line-height: 1.7rem;
		border-radius: 0 !important;
	}
	.form-label-group > label {
		line-height: 1.7rem;
	}
	.form-label-group input:not(:placeholder-shown)~label {
		font-size: 0;
	}
	.form-label-group input:not(:placeholder-shown) {
		padding: 0;
	}
}

.form-signin {
	width: 100%;
	max-width: 400px;
	padding: 2.5rem;
	margin: auto;
	background: rgba(0, 0, 0, 0.8);
	box-shadow: 0px 0px 17px 3px rgba(26,26,26,.8);
}

small {
	font-weight: bold !important;
}

.btn-primary.disabled, .btn-primary:disabled {
		background: #252525;
		border-color: initial;
}

a, a:active, a:hover, a:focus {
	outline: none;
	background: transparent;
	box-shadow: none;
	text-decoration: none;
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: unset;
}

.form-label-group {
	position: relative;
	margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
	height: 3.5rem;
	padding: .75rem;
	text-align: center;
	color: #eee;
	// font-weight: bold;
}

.form-label-group > label {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	margin-bottom: 0; /* Override default `<label>` margin */
	line-height: 1.5;
	color: #495057;
	font-weight: normal;
	pointer-events: none;
	cursor: text; /* Match the input under the label */
	border: 1px solid transparent;
	transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
	color: transparent;
}

.form-label-group input:-ms-input-placeholder {
	color: transparent;
}

.form-label-group input::-ms-input-placeholder {
	color: transparent;
}

.form-label-group input::-moz-placeholder {
	color: transparent;
}

.form-label-group input::placeholder {
	color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
	padding-top: 1.25rem;
	padding-bottom: .25rem;
	text-align: center;
}

.form-label-group input:not(:placeholder-shown) ~ label {
	padding-top: .25rem;
	padding-bottom: .25rem;
	font-size: .7rem;
	color: #777;
    text-transform: uppercase;
    letter-spacing: .5px;
}

.form-control {
	background: rgba(54, 54, 54, 0.3);
	border-radius: 0;
	-webkit-appearance: none;
	text-align: center;
	border-bottom: 2px solid rgba(255, 255, 255, 0.5);
	border-top: 0; border-left: 0; border-right: 0;
	font-size: 1.5rem;
	line-height: 1.7rem;
	&:focus {
		background: rgba(54, 54, 54, 0.3);
		border-bottom: 4px solid #fff;
		border-top: 0; border-left: 0; border-right: 0;
		box-shadow: none;
		color: #eee;
	}
}

.form-control:disabled, .form-control[readonly] {
	background: rgba(54, 54, 54, 0.3);
	opacity: .7;
}

.btn {
	border: none;
	cursor: pointer;
	padding: .5rem 1rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: bold;
	outline: none;
	position: relative;
	span {
		padding: 1rem;
		width: 100%;
		display: block;
		height: 100%;
	}
}

.btn-lg {
	padding: 0;
}

.btn-primary {
	background: #252525;
	border-radius: 0 0 5px 5px;
	color: #efefef;
	box-shadow: 0 6px #1f1f1f;
	&:focus {
		outline: none;
		box-shadow: 0 0px #1f1f1f;
	}
	&:hover {
		background: #252525;
		top: 2px;
		box-shadow: 0 4px #1f1f1f;
	}
	&:active {
		outline: none;
		background: #252525 !important;
		border-color: transparent !important;
		top: 6px;
		box-shadow: 0 0px #1f1f1f;
	}
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
	display: none;
  }
  .form-label-group input::-ms-input-placeholder {
	color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
	display: none;
  }
  .form-label-group input:-ms-input-placeholder {
	color: #777;
  }
}


.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@media (min-width: 768px) {
	.bd-placeholder-img-lg {
		font-size: 3.5rem;
	}
}

.hidden {
    display: none!important;
    visibility: hidden!important;
}

.modal-backdrop.show {
    opacity: .8;
}

.modal-content {
    background-color: #000000;
    color: #eee;
	text-align: center;
	.form-control {
		background: rgba(54, 54, 54, 0.4);
	}
	.modal-header {
		border: none;
		display: block;
		.modal-title {
			margin-bottom: 0;
			line-height: 1.5;
			text-transform: uppercase;
			font-weight: bold;
			letter-spacing: 1px;
		}
		.close {
			padding: 0rem 1rem;
			margin: 0;
			position: absolute;
			top: 0;
			right: 0;
			background: transparent;
			color: #585858;
			border: none;
			font-size: 2rem;
		}
	}
	.modal-body {
		padding: 1rem 1rem 3rem;
	}
}
